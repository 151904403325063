import { Spinner } from '@bindystreet/bindystreet.kit.react';
import { IEvent } from 'Colugo/interfaces/event/IEvent';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import EventOperations from 'Colugo/operations/events/EventOperations';
import AnalyticsSnapshot from 'components/dashboard/AnalyticsSnapshot';
import BusinessHeroCard from 'components/dashboard/business/BusinessHeroCard';
import EventCard from 'components/events/EventCard';
import ConfirmationPopup from 'components/shared/ConfirmationPopup';
import EmptyCard from 'components/shared/EmptyCard';
import { ManagerContext } from 'provider/manager/managerProvider';
import { UserContext } from 'provider/user/userProvider';
import { useContext, useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import { useErrorToast } from 'utility/hooks/useErrorToast';

const eventOperations = container.resolve(EventOperations);

function BusinessHome() {
  const {
    listing,
    businessOwnsClaimsRequests,
    isListingLoading,
    selectedOwnedId,
    mutateListing,
    setSelectedOwnedId
  } = useContext(ManagerContext);
  const { user } = useContext(UserContext);
  const { errorToast } = useErrorToast();
  const navigate = useNavigate();
  const [eventToBeDeleted, setEventToBeDeleted] = useState<IEvent | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [localEvents, setLocalEvents] = useState<IEvent[]>(
    listing?.events || []
  );

  useEffect(() => {
    setLocalEvents(listing?.events || []);
  }, [listing?.events]);

  useEffect(() => {
    if (
      businessOwnsClaimsRequests?.owns &&
      businessOwnsClaimsRequests.owns.length > 0 &&
      businessOwnsClaimsRequests.owns[0].id &&
      businessOwnsClaimsRequests.owns.some((o) => o.id === selectedOwnedId) ===
        false
    ) {
      setSelectedOwnedId(businessOwnsClaimsRequests.owns[0].id);
      navigate('/');
    } else if (
      !listing &&
      (businessOwnsClaimsRequests.owns.length === 0 || !selectedOwnedId)
    ) {
      navigate(PuffinRoutes.ManageBusiness);
    }
  }, [
    listing,
    navigate,
    businessOwnsClaimsRequests.owns,
    selectedOwnedId,
    setSelectedOwnedId
  ]);

  if (isListingLoading) {
    return <Spinner />;
  }

  async function handleDeleteEventAsync() {
    if (!eventToBeDeleted?.id) {
      errorToast('No event found to be deleted. Please try again.');
      return;
    }
    setIsLoading(true);
    const { error } = await eventOperations.deleteAsync(eventToBeDeleted.id);
    setIsLoading(false);
    if (error) {
      errorToast('Something went wrong, please try again.');
      return;
    }
    toast.success('Event deleted.');
    setEventToBeDeleted(undefined);
    setLocalEvents(
      localEvents?.filter((e) => e.id !== eventToBeDeleted.id) || []
    );

    if (listing) {
      mutateListing({
        ...listing,
        events: listing.events?.filter((e) => e.id !== eventToBeDeleted.id)
      });
    }
  }

  const confirmDeleteModal = (
    <ConfirmationPopup
      isModalOpen={!!eventToBeDeleted}
      handleClickConfirmButton={handleDeleteEventAsync}
      closeModal={() => setEventToBeDeleted(undefined)}
      popupLabel={`Delete this Event?`}
      confirmButtonText={`Delete Event`}
      isErrorButton={true}
      isLoading={isLoading}
    >
      <div className="font-nunito text-lg font-normal">
        Please note, once an event is permanently deleted, this action cannot be
        undone. Are you sure you want to delete this event?
      </div>
    </ConfirmationPopup>
  );

  return (
    <div className="flex flex-row w-full overflow-y-auto">
      <div className="mx-auto mt-10" style={{ width: '1162px' }}>
        <h1 className="font-bold mb-6" style={{ fontSize: '28px' }}>
          Welcome, {user?.firstName || ''} {user?.lastName || ''}!
        </h1>
        {listing && <BusinessHeroCard listing={listing} />}
        {listing?.analytics && (
          <div>
            <div className="flex flex-row mt-4">
              <div className="text-xl font-bold">Analytics</div>
              <div className="flex-grow" />
              <span
                className="text-lg text-primary font-semibold cursor-pointer flex flex-row"
                onClick={() => navigate(PuffinRoutes.Analytics)}
              >
                View Analytics
                <MdKeyboardArrowRight size={24} className="mt-1" />
              </span>
            </div>
            <AnalyticsSnapshot analytics={listing.analytics} />
          </div>
        )}
        <div className="flex justify-between items-center mt-6 mb-4">
          <p
            className="text-xl font-bold"
            onClick={() =>
              navigate(
                'manage-events/event/3d7bf64d-72bc-ee11-b660-6045bd13bbcf/promotions'
              )
            }
          >
            Your Events
          </p>
          <span
            className="flex items-center cursor-pointer text-primary"
            onClick={() => navigate(PuffinRoutes.ManageEvents)}
          >
            <p className="text-lg font-semibold">Manage Events</p>
            <MdKeyboardArrowRight size={24} className="mt-1" />
          </span>
        </div>
        {confirmDeleteModal}
        {localEvents && localEvents.length > 0 ? (
          localEvents.map((event) => (
            <EventCard
              event={event}
              key={event.id}
              onClickDelete={() => setEventToBeDeleted(event)}
              hasPromotion={event.promotions && event.promotions.length > 0}
            />
          ))
        ) : (
          <EmptyCard text="No Events found." />
        )}
      </div>
    </div>
  );
}

export default BusinessHome;
