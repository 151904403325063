import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import EditListingEditor from 'components/listings/EditListingEditor';
import { ManagerContext } from 'provider/manager/managerProvider';
import { useContext } from 'react';

function BusinessDetails() {
  const { listing, mutateOwnedListing, isListingLoading } =
    useContext(ManagerContext);

  if (isListingLoading) {
    return <Spinner />;
  }
  if (!listing) {
    return (
      <ErrorPage>
        <span>{`Error fetching the listing`}</span>
      </ErrorPage>
    );
  }
  return (
    <div className="flex flex-row w-full overflow-y-auto">
      <div className="mt-10 mx-auto">
        <EditListingEditor listing={listing} mutate={mutateOwnedListing} />
      </div>
    </div>
  );
}

export default BusinessDetails;
