import { ErrorPage, FlyoutMenuItem } from '@bindystreet/bindystreet.kit.react';
import { IAnalytics } from 'Colugo/interfaces/common/IAnalytics';
import AnalyticsOperations from 'Colugo/operations/analytics/AnalyticsOperations';
import AnalyticsSnapshot from 'components/dashboard/AnalyticsSnapshot';
import ImprovedFlyoutMenu from 'components/shared/ImprovedFlyoutMenu';
import { ManagerContext } from 'provider/manager/managerProvider';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import BusinessListingAnalytics from './BusinessListingAnalytics';

const analyticsOperations = container.resolve(AnalyticsOperations);

export const flyoutMenuItems = [
  { label: '7 Days', value: '7' },
  { label: '14 Days', value: '14' },
  { label: '30 Days', value: '30' },
  { label: '2 Months', value: '60' },
  { label: '6 Months', value: '183' }
];

function BusinessAnalytics() {
  const [selectedNOfDays, setSelectedNOfDays] = useState<string | undefined>(
    flyoutMenuItems[0].value
  );
  const [analytics, setAnalytics] = useState<IAnalytics | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { listing } = useContext(ManagerContext);

  async function getAnalyticsForFlyoutOption(fmi: FlyoutMenuItem) {
    setSelectedNOfDays(fmi.value);
    setIsLoading(true);
    const { data: localAnalytics, error } =
      await analyticsOperations.getAnalyticsAsync(listing?.id, fmi.value);
    setIsLoading(false);
    if (error || !localAnalytics) {
      toast.error('Unable to fetch analytics, please try again.');
      return;
    }
    setAnalytics(localAnalytics);
  }

  if (!listing?.analytics) {
    return (
      <ErrorPage>
        <span>Unable to fetch analytics, please try again.</span>
      </ErrorPage>
    );
  }

  const selectedFlyoutMenuItem = flyoutMenuItems.find(
    (fmi) => fmi.value === selectedNOfDays
  );

  const topComponent = (
    <div className="flex flex-row py-4 px-6 border-b border-outline">
      <div className="text-xl font-bold mt-3">Overall Analytics</div>
      <div className="flex-grow" />
      <ImprovedFlyoutMenu
        flyoutMenuItems={flyoutMenuItems}
        onChange={getAnalyticsForFlyoutOption}
        selectedItem={selectedFlyoutMenuItem}
        wrapperClassName="rounded-lg"
      />
    </div>
  );

  return (
    <div className="flex flex-row w-full overflow-y-auto">
      <div className="mt-10 mx-auto">
        <div className="text-2xl font-bold">Analytics</div>
        <AnalyticsSnapshot
          analytics={analytics ?? listing?.analytics}
          topComponent={topComponent}
          isLoading={isLoading}
          nOfDays={Number(selectedNOfDays)}
        />

        <BusinessListingAnalytics
          entityId={listing.id}
          initialAnalytics={listing.analytics}
        />
      </div>
    </div>
  );
}

export default BusinessAnalytics;
