import { IAnalytics } from 'Colugo/interfaces/common/IAnalytics';
import AnalyticsOperations from 'Colugo/operations/analytics/AnalyticsOperations';
import { renderMetric } from 'components/dashboard/AnalyticsSnapshot';
import ImprovedFlyoutMenu, {
  FlyoutMenuItem
} from 'components/shared/ImprovedFlyoutMenu';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import { flyoutMenuItems } from './BusinessAnalytics';

const analyticsOperations = container.resolve(AnalyticsOperations);

type Props = {
  entityId?: string;
  initialAnalytics: IAnalytics;
};

export default function BusinessAnalytics(props: Props) {
  const { entityId, initialAnalytics } = props;
  const [selectedNOfDays, setSelectedNOfDays] = useState<string | undefined>(
    flyoutMenuItems[0].value
  );
  const [analytics, setAnalytics] = useState<IAnalytics | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const selectedFlyoutMenuItem = flyoutMenuItems.find(
    (fmi) => fmi.value === selectedNOfDays
  );

  async function getAnalyticsForFlyoutOption(fmi: FlyoutMenuItem) {
    setSelectedNOfDays(fmi.value);
    setIsLoading(true);
    const { data: localAnalytics, error } =
      await analyticsOperations.getAnalyticsAsync(entityId, fmi.value);
    setIsLoading(false);
    if (error || !localAnalytics) {
      toast.error('Unable to fetch analytics, please try again.');
      return;
    }
    setAnalytics(localAnalytics);
  }

  const analyticsToRender = analytics ?? initialAnalytics;

  const horizontalDivider = (
    <div className="border-b border-outline my-4" style={{ width: '224px' }} />
  );

  console.log(analyticsToRender.clicks);

  return (
    <div className="flex flex-col bg-white rounded-lg border border-outline mt-4">
      <div className="flex flex-row py-4 px-6 border-b border-outline">
        <div className="text-xl font-bold mt-3">Overall Analytics</div>
        <div className="flex-grow" />
        <ImprovedFlyoutMenu
          flyoutMenuItems={flyoutMenuItems}
          onChange={getAnalyticsForFlyoutOption}
          selectedItem={selectedFlyoutMenuItem}
          wrapperClassName="rounded-lg"
        />
      </div>
      <div className="flex flex-row">
        <div className="flex flex-col">
          <div className="flex flex-col px-2 py-6">
            {renderMetric(
              'Impressions',
              analyticsToRender.impressions,
              isLoading,
              Number(selectedNOfDays),
              '224px'
            )}
            {horizontalDivider}
            {renderMetric(
              'Total Clicks',
              analyticsToRender.clicks,
              isLoading,
              Number(selectedNOfDays),
              '224px'
            )}
            {horizontalDivider}
            {renderMetric(
              'Click-through-Rate',
              analyticsToRender.clickThroughRate,
              isLoading,
              Number(selectedNOfDays),
              '224px',
              true
            )}
          </div>
        </div>
        <div className="bg-error1 text-white text-2xl font-bold p-48 px-72 uppercase">
          Big scary graph to go here
        </div>
      </div>
    </div>
  );
}
