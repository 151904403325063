import { useOutsideClick } from '@bindystreet/bindystreet.kit.react';
import { useRef, useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

type Props = {
  flyoutMenuItems: FlyoutMenuItem[];
  onChange: (flyoutMenuItem: FlyoutMenuItem) => void;
  selectedItem?: FlyoutMenuItem;
  bottomFlyoutMenuItem?: string;
  onSelectBottomFlyoutMenuItem?: () => void;
  icon?: JSX.Element;
  wrapperClassName?: string;
};

export type FlyoutMenuItem = {
  label: string;
  value: string;
};

function ImprovedFlyoutMenu(props: Props) {
  const {
    flyoutMenuItems,
    onChange,
    selectedItem,
    bottomFlyoutMenuItem,
    onSelectBottomFlyoutMenuItem,
    icon,
    wrapperClassName
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => {
    setIsOpen(!isOpen);
  });

  function handleSelectItem(item: FlyoutMenuItem) {
    setIsOpen(false);
    onChange(item);
  }

  function handleSelectBottomFlyoutMenuItem() {
    setIsOpen(false);
    onSelectBottomFlyoutMenuItem?.();
  }

  return (
    <div className="cursor-pointer relative font-inter">
      <div className="w-full" style={{ minWidth: '248px' }}>
        <div>
          <div
            className={`flex flex-row border-surfaceContainer border px-4 py-2 ${wrapperClassName}`}
            style={{ userSelect: 'none' }}
            onClick={() => setIsOpen(!isOpen)}
          >
            {icon}
            <span className="mt-0.5 ml-2">{selectedItem?.label}</span>
            <div className="flex flex-grow" />
            {isOpen ? (
              <MdKeyboardArrowUp
                className="cursor-pointer text-3xl font-extrabold"
                style={{ minWidth: '1.5rem' }}
                type="button"
              />
            ) : (
              <MdKeyboardArrowDown
                className="cursor-pointer text-3xl font-extrabold"
                style={{ minWidth: '1.5rem' }}
                type="button"
              />
            )}
          </div>
          {isOpen && (
            <div
              className="flex flex-col absolute top-14 right-0 border border-outline shadow bg-white rounded-lg w-full p-4"
              ref={ref}
            >
              {flyoutMenuItems.map((item) => (
                <div
                  onClick={() => handleSelectItem(item)}
                  key={item.value}
                  className={`${
                    selectedItem?.value === item.value
                      ? 'text-primary'
                      : 'text-onSurfaceVariant'
                  } mb-2 truncate`}
                >
                  {item.label}
                </div>
              ))}
              <div onClick={handleSelectBottomFlyoutMenuItem}>
                {bottomFlyoutMenuItem}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ImprovedFlyoutMenu;
