import { Spinner } from '@bindystreet/bindystreet.kit.react';
import { IAnalyticField } from 'Colugo/interfaces/common/IAnalyticField';
import { IAnalytics } from 'Colugo/interfaces/common/IAnalytics';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';

type Props = {
  analytics: IAnalytics;
  topComponent?: JSX.Element;
  isLoading?: boolean;
  nOfDays?: number;
};

export function renderMetric(
  name: string,
  analyticField: IAnalyticField,
  isLoading: boolean,
  nOfDays: number,
  width: string,
  isPercentage: boolean = false
) {
  return (
    <div className="flex flex-col px-4" style={{ width: width }}>
      <span className="text-onSurfaceVariant font-semibold text-lg">
        {name}
      </span>
      <div className="flex flex-row mt-12">
        <span className="text-4xl">
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            analyticField.count.toString() + (isPercentage ? '%' : '')
          )}
        </span>
        <div className="flex-grow" />
        <div className="flex flex-col">
          {isLoading ? <Spinner size="sm" /> : renderDelta(analyticField.delta)}
          <span className="font-normal text-sm text-onSurfaceVariant">
            From {getFromDateString(nOfDays)}
          </span>
        </div>
      </div>
    </div>
  );
}

export function getFromDateString(nOfDays: number) {
  return new Date(
    Date.now() - nOfDays * 24 * 60 * 60 * 1000
  ).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
}

export function renderDelta(delta: number) {
  const deltaString = delta === 0 ? 'N/A' : 100 * delta + '%';
  return (
    <div
      className={`flex flex-row ${delta > 0 && 'text-success'} ${
        delta < 0 && 'text-error1'
      }`}
    >
      {delta > 0 && <BiSolidUpArrow size={10} className="mt-1.5" />}
      {delta < 0 && <BiSolidDownArrow size={10} className="mt-1.5" />}
      <span
        className={`ml-1 text-sm font-bold ${
          delta === 0 ? 'text-onSurfaceVariant' : ''
        } `}
      >
        {deltaString}
      </span>
    </div>
  );
}

export default function AnalyticsSnapshot(props: Props) {
  const { analytics, topComponent, isLoading = false, nOfDays = 7 } = props;

  const verticalDivider = (
    <div className="border-l border-outline" style={{ height: '138px' }} />
  );

  return (
    <div className="font-inter">
      <div className="flex flex-col bg-white rounded-lg border border-outline mt-4">
        {topComponent}
        <div className="flex flex-row px-2 py-6">
          {renderMetric(
            'Total Impressions',
            analytics.impressions,
            isLoading,
            nOfDays,
            '290px'
          )}
          {verticalDivider}
          {renderMetric(
            'Total Clicks',
            analytics.clicks,
            isLoading,
            nOfDays,
            '290px'
          )}
          {verticalDivider}
          {renderMetric(
            'Click-through rate',
            analytics.clickThroughRate,
            isLoading,
            nOfDays,
            '290px',
            true
          )}
          {verticalDivider}
          {renderMetric(
            'Total Saves',
            analytics.collects,
            isLoading,
            nOfDays,
            '290px'
          )}
        </div>
      </div>
    </div>
  );
}
