export enum PuffinRoutes {
  // None authenticated routes
  SignUp = '/signup',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  AccountCreated = '/account-created',
  VerifyEmail = '/verify-email',

  // Authenticated routes
  EditListing = '/listing/:listingId/*',
  EditEvent = '/event/:eventId/*',
  SuggestionsBucket = '/suggestions',
  ImportListings = '/import-listings',
  ImportEvents = '/import-events',
  EditSuggestion = '/suggestions/:suggestionBucketId/*',
  ListingSuggestionBucket = '/suggestions/listing/:listingId/*',
  CreateEvent = '/create-event',
  CreateListing = '/create-listing',

  // Business routes
  SubmitClaim = '/submit-claim',
  SubmitBusinessRequest = '/submit-business-request',
  ManageBusiness = '/manage-business',
  ManageEvents = '/manage-events',
  ManagerEditEvent = '/manage-events/event/:eventId/*',
  BusinessDetails = '/business-details/:businessId/*',
  BusinessClaims = 'business-claims/*',
  BusinessRequests = 'business-requests/*',
  RecentEdits = '/recent-edits',
  Analytics = '/analytics',

  //Nested Business Routes
  NewBusinessClaimOrRequests = 'new',
  AcceptedBusinessClaimOrRequests = 'accepted',
  RejectedBusinessClaimOrRequests = 'rejected',

  // Admin Routes
  ManageAccounts = '/manage-accounts',
  ManagerCreateEvent = '/manage-events/create-event'
}
